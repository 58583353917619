import cloneDeep from 'lodash.clonedeep';
import sortBy from 'lodash.sortby';
import set from 'lodash.set';
import { urlRedirect } from '@digitalhouse-tech/ts-lib-checkout-url-mapper';
import { CountryCodes } from '@digitalhouse-tech/ts-lib-checkout-url-mapper/.dist/util/countryMap';
import * as Sentry from '@sentry/nextjs';
import apolloClient, { ErrorPolicy, FetchPolicy } from '../graphql/client';
import {
  GET_DEFAULT_OFFER,
  GET_DEFAULT_OFFERS,
  GET_OFFER,
  GET_OFFERS,
  GET_PRODUCT,
  GET_PRODUCTS,
} from '../graphql/queries';
import {
  ICurrency,
  IOffer,
  IOfferResponse,
  IProduct,
  IProductGroup,
  IProductOfferResponse,
  IProductsResponse,
} from '../graphql/model/interfaces';
import { IAllowedCheckoutQueryParams } from '../types/checkout.type';
import { CHECKOUT_UTM_SOURCE, SUPPORTED_COUNTRIES } from './constants';
import { defaultLocale, getCountry, getLocale } from './common';

const formatProduct = (
  product: (IProduct & IProductGroup),
  wareId: number,
  currency: ICurrency,
  label: string,
  defaultOffer: IOffer,
): (IProduct & IProductGroup
) => ({
  ...product,
  wareId,
  currency,
  label: product.duration?.name ? label.replace(new RegExp(product.duration?.name, 'i'), '') : label,
  defaultOffer,
});

const getData = (query, variables) => apolloClient.query(
  {
    query,
    variables,
    fetchPolicy: FetchPolicy.cacheFirst,
    errorPolicy: ErrorPolicy.all,
  },
);

export const getProducts = async (id1: number, id2: number | null, country: string): Promise<IProductsResponse> => {
  let multipleWares;
  try {
    const both = !!id1 && !!id2;
    multipleWares = await getData(both ? GET_PRODUCTS : GET_PRODUCT, { id1, id2, country });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    multipleWares = null;
    // TODO: implement error handler
    if (error.networkError) Sentry.captureException(error);
    if (error.graphQLErrors?.length) Sentry.captureException(error);
  }

  if (multipleWares?.errors) {
    multipleWares.errors = undefined;
  }

  return multipleWares;
};

export const getDefaultOffer = async (id1: number, id2?: number): Promise<IProductOfferResponse> => {
  let defaultOffers;
  try {
    const both = !!id1 && !!id2;
    defaultOffers = await getData(both ? GET_DEFAULT_OFFERS : GET_DEFAULT_OFFER, { id1, id2 });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // TODO: implement error handler
    if (error.networkError) Sentry.captureException('Network Error: ', error.networkError);
    if (error.graphQLErrors?.length) Sentry.captureException('API Graph Error:', error.graphQLErrors[0].message);
  }

  return defaultOffers?.data ?? null;
};

export const getOffers = async (token: string[], wareId: number[], country?: string): Promise<IOfferResponse> => {
  let offers;

  try {
    const variables = {
      token1: token[0] ?? '',
      token2: token[1] ?? '',
      id1: wareId[0] ?? '',
      id2: wareId[1] ?? '',
      country,
    };

    offers = await getData(!!token[0] && !!token[1] ? GET_OFFERS : GET_OFFER, variables);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // TODO: implement error handler
    if (error.networkError) Sentry.captureException('Network Error: ', error.networkError);
    if (error.graphQLErrors?.length) Sentry.captureException('API Graph Error:', error.graphQLErrors[0].message);
  }

  offers?.errors?.forEach((e) => {
    Sentry.captureException(
      e.message,
      {
        extra: {
          wares: [wareId[0], wareId[1]].join(),
          token: [token[0], token[1]].join(),
          country,
        },
      },
    );
  });

  return offers?.data ?? null;
};

export const applyOffer = (oldOffers, newOffer, path) => (
  newOffer ? set(cloneDeep(oldOffers), path, newOffer) : oldOffers
);

export const applyProductFormat = (
  waresId: number[],
  country,
  currencyCode: string,
  currencySymbol: string,
  checkoutProducts,
  offers,
): { products: IProduct[], productsNextStarts: IProduct[] } => {
  if (!offers?.length) {
    return {
      productsNextStarts: [],
      products: [],
    };
  }

  const defaultOffers: any = [];
  const currency = {
    symbol: currencySymbol,
    code: currencyCode,
  };

  try {
    waresId.forEach((ware, index) => {
      const defaultOffer = {
        ...offers[index].offer1,
        ...offers[index].defaultOffer,
        country,
      };
      defaultOffer.plans = defaultOffer.plans ? sortBy(defaultOffer.plans, ['currency', 'installmentAmount']) : [];

      const uniquePlans: any[] = [];
      const seen = new Set();

      defaultOffer.plans.forEach((plan) => {
        const key = `${plan.currency}-${plan.id}`;
        if (!seen.has(key)) {
          seen.add(key);
          uniquePlans.push(plan);
        }
      });

      defaultOffer.plans = uniquePlans;

      const product = checkoutProducts[index]
        ? formatProduct(checkoutProducts[index], ware, currency, offers[index].name, defaultOffer)
        : {} as any;

      defaultOffers.push(product);
    });

    const productsSorted = sortBy(defaultOffers, [({ startsAt }) => new Date(startsAt).getTime()]);
    const productsNextStarts = productsSorted.filter(
      ({ startsAt, defaultOffer: offer }: any) => (
        offer?.isSubscription
        || (new Date(startsAt).getTime() > new Date().getTime())
      ),
    );

    return {
      productsNextStarts,
      products: productsSorted,
    };
  } catch (error) {
    console.error(error);

    return {
      productsNextStarts: [],
      products: [],
    };
  }
};

const formatCountryCode = (code: string) => {
  if (!code) return code;

  const [language, country] = code.split('-');

  if (!country) return code;

  return `${language}-${country.toUpperCase()}`;
};

export const getCheckoutUrl = (
  checkout: IProductGroup,
  groupId: string,
  queryParams: IAllowedCheckoutQueryParams,
  sandbox: boolean,
  countryCode: string,
  plan?: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  /** @derecated */ version: 'legacy' | 'new' = 'new',
) => {
  const { wareId } = checkout;
  let baseUrl = '';

  baseUrl = [
    process.env.NEXT_PUBLIC_CHECKOUT_NEW_URL,
    wareId,
    `personal-data?token=${checkout.offerId}&planId=${plan}`,
  ].join('/');

  const url = new URL(baseUrl);

  // Agregar o reemplazar los valores de queryParams en url.searchParams
  Object.keys(queryParams).forEach((key) => {
    url.searchParams.set(key, queryParams[key]);
  });

  // Iterar sobre los parámetros de la URL y eliminar los que estén vacíos
  url.searchParams.forEach((value, key) => {
    if (!value) {
      url.searchParams.delete(key);
    }
  });

  // Devolver la URL completa
  return url.toString();
};

interface IGetLocalePlans {
  geoCountry: string;
  locale: string;
  products?: IProductGroup[];
  currency?: string;
  allCurrencies?: any[];
}

export const getLocalePlans = ({
  products, geoCountry, locale, currency = '', allCurrencies = [],
}: IGetLocalePlans) => {
  let currentLocale = getLocale(locale === defaultLocale.key ? geoCountry : locale);
  let currentCurrency = currentLocale.currencyCode;

  if (currency && allCurrencies?.length) {
    currentCurrency = currency;
    currentLocale = { countryCode: allCurrencies?.find((c) => c.alias === currency)?.country?.locale } as any;
  }

  const isUnavailable = false;
  const plans = products?.flatMap((item) => item.defaultOffer?.plans ?? []);
  const hasPlans = plans?.some((p) => p.currency === currentCurrency);

  return {
    currentLocale,
    currentCurrency,
    isUnavailable,
    hasPlans,
  };
};
